import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import LandingPage from './pages/LandingPage';
import Admin from './pages/Admin';
import AllergenPage from './pages/AllergenPage';
import MenuPage from './pages/MenuPage';
import CleaningPage from './pages/CleaningPage';
import ReportsPage from './pages/ReportsPage';
import DiaryPage from './pages/DiaryPage';
import StaffPage from './pages/StaffPage';
import TemperaturePage from './pages/TemperaturePage';
import WeeklyReviewPage from './pages/WeeklyReviewPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import CustomisationPage from './pages/CustomisationPage';
import ProtectedRoute from './pages/ProtectedRoute';
import NotAuthorisedPage from './pages/NotAuthorisedPage';

function App() {
  return (
    <ThemeProvider>
      <div>
        <Routes>
          {/* Unprotected routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot" element={<ForgotPasswordPage />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="/not-authorized" element={<NotAuthorisedPage />} />
          <Route path="/menu" element={<MenuPage />} />

          {/* Protected routes */}
          <Route path="/" element={<ProtectedRoute><LandingPage /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute requiredRole="Admin"><Admin /></ProtectedRoute>} />
          <Route path="/allergens" element={<ProtectedRoute><AllergenPage /></ProtectedRoute>} />
          <Route path="/cleaning" element={<ProtectedRoute><CleaningPage /></ProtectedRoute>} />
          <Route path="/reports" element={<ProtectedRoute><ReportsPage /></ProtectedRoute>} />
          <Route path="/notes" element={<ProtectedRoute><DiaryPage /></ProtectedRoute>} />
          <Route path="/staff" element={<ProtectedRoute><StaffPage /></ProtectedRoute>} />
          <Route path="/temperature" element={<ProtectedRoute><TemperaturePage /></ProtectedRoute>} />
          <Route path="/weekly-reviews" element={<ProtectedRoute><WeeklyReviewPage /></ProtectedRoute>} />
          <Route path="/customisation" element={<ProtectedRoute requiredRole="Admin"><CustomisationPage /></ProtectedRoute>} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;