import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import moment from 'moment-timezone';

const ReportsPage = () => {
  const [temperatureReports, setTemperatureReports] = useState([]);
  const [cleaningRecords, setCleaningRecords] = useState([]);
  const [groupedTemperatureReports, setGroupedTemperatureReports] = useState({});
  const [groupedCleaningReports, setGroupedCleaningReports] = useState({});
  const [selectedSystem, setSelectedSystem] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [systemOptions, setSystemOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [temperatureLimit, setTemperatureLimit] = useState(15);
  const [cleaningLimit, setCleaningLimit] = useState(15);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const [tempRes, cleanRes] = await Promise.all([
        fetch(`/api/temperatureReports`, { credentials: 'include' }),
        fetch(`/api/cleaningRecords`, { credentials: 'include' })
      ]);

      if (tempRes.ok && cleanRes.ok) {
        const temperatureData = await tempRes.json();
        const cleaningData = await cleanRes.json();

        setTemperatureReports(temperatureData);
        setCleaningRecords(cleaningData);

        groupReportsByDate(temperatureData, 'report_date', setGroupedTemperatureReports);
        groupReportsByDate(cleaningData, 'record_timestamp', setGroupedCleaningReports);

        setSystemOptions([...new Set(temperatureData.map(report => report.system_name))]);
        setTaskOptions([...new Set(cleaningData.map(report => report.task_name))]);
      } else {
        console.error('Error fetching reports:', tempRes.status, tempRes.statusText, cleanRes.status, cleanRes.statusText);
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  const groupReportsByDate = (data, dateField, setGroupedData) => {
    const grouped = data.reduce((acc, item) => {
      const reportDate = moment(item[dateField]).format('DD/MM/YYYY');
      if (!acc[reportDate]) {
        acc[reportDate] = [];
      }
      acc[reportDate].push(item);
      return acc;
    }, {});
    setGroupedData(grouped);
  };

  const handleSystemChange = (e) => setSelectedSystem(e.target.value);
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setSelectedDay('');
  };
  const handleYearChange = (e) => setSelectedYear(e.target.value);
  const handleDayChange = (e) => setSelectedDay(e.target.value);
  const handleTaskChange = (e) => setSelectedTask(e.target.value);

  const filterReports = (reports, type) => {
    return Object.keys(reports).reduce((acc, date) => {
      const reportDate = moment(date, 'DD/MM/YYYY');
      if (selectedMonth && reportDate.month() + 1 !== parseInt(selectedMonth)) return acc;
      if (selectedYear && reportDate.year() !== parseInt(selectedYear)) return acc;
      if (selectedDay && reportDate.date() !== parseInt(selectedDay)) return acc;

      let filteredReports = reports[date];
      if (type === 'temperature' && selectedSystem) {
        filteredReports = filteredReports.filter(report => report.system_name === selectedSystem);
      }
      if (type === 'cleaning' && selectedTask) {
        filteredReports = filteredReports.filter(report => report.task_name === selectedTask);
      }

      if (filteredReports.length > 0) {
        acc[date] = filteredReports;
      }

      return acc;
    }, {});
  };

  const renderTableLayout = (reports, type, limit, setLimit) => {
    const reportCount = Object.values(reports).flat().length;
    const sortedReports = Object.keys(reports)
      .sort((a, b) => moment(a, 'DD/MM/YYYY').diff(moment(b, 'DD/MM/YYYY')))
      .reduce((acc, date) => {
        if (acc.length < limit) {
          const remainingLimit = limit - acc.length;
          acc.push(...reports[date].slice(0, remainingLimit));
        }
        return acc;
      }, []);
    
    const headers = type === 'temperature' 
      ? ['System', 'Temperature (°C)', 'Submitted On'] 
      : ['Task', 'Cleaner', 'Time'];
  
    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow">
          <thead className="bg-gray-100">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedReports.map((report, index) => (
              <tr key={`${report.date}-${index}`} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                {type === 'temperature' ? (
                  <>
                    <td className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">{report.system_name}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">{report.temperature}°C</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">{moment(report.report_date).format('DD/MM/YYYY')}</td>
                  </>
                ) : (
                  <>
                    <td className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">{report.task_name}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">{report.cleaner_name}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left whitespace-nowrap w-1/3">{moment(report.record_timestamp).format('DD/MM/YYYY h:mm:ss A')}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex gap-4 mt-4">
          {reportCount > limit && (
            <button onClick={() => setLimit(limit + 15)} className="px-4 py-2 bg-blue-600 text-white rounded">Load More</button>
          )}
          {limit > 15 && (
            <button onClick={() => setLimit(Math.max(15, limit - 15))} className="px-4 py-2 bg-gray-600 text-white rounded">Show Less</button>
          )}
        </div>
      </div>
    );
  };     

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="Reports" />
      <Navbar />
      <main className="flex-1 p-4 sm:p-8">
        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-700 mb-4">View Reports</h2>

          <div className="mb-4 grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label htmlFor="system" className="block text-gray-600 mb-2">Select System:</label>
              <select id="system" value={selectedSystem} onChange={handleSystemChange} className="p-2 border border-gray-300 rounded w-full">
                <option value="">All Systems</option>
                {systemOptions.map((system, index) => (
                  <option key={index} value={system}>{system}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="month" className="block text-gray-600 mb-2">Select Month:</label>
              <select id="month" value={selectedMonth} onChange={handleMonthChange} className="p-2 border border-gray-300 rounded w-full">
                <option value="">All Months</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>{moment().month(i).format('MMMM')}</option>
                ))}
              </select>
            </div>

            {selectedMonth && (
              <div>
                <label htmlFor="day" className="block text-gray-600 mb-2">Select Day:</label>
                <select id="day" value={selectedDay} onChange={handleDayChange} className="p-2 border border-gray-300 rounded w-full">
                  <option value="">All Days</option>
                  {Array.from({ length: 31 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            )}

            <div>
              <label htmlFor="year" className="block text-gray-600 mb-2">Select Year:</label>
              <select id="year" value={selectedYear} onChange={handleYearChange} className="p-2 border border-gray-300 rounded w-full">
                <option value="">All Years</option>
                {[...new Set([...temperatureReports, ...cleaningRecords].map(report => moment(report.report_date || report.record_timestamp).year()))]
                  .map(year => <option key={year} value={year}>{year}</option>)}
              </select>
            </div>
          </div>

          <div id="temperature-reports-section" className="mb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Temperature Records</h3>
            {renderTableLayout(filterReports(groupedTemperatureReports, 'temperature'), 'temperature', temperatureLimit, setTemperatureLimit)}
          </div>

          <div id="cleaning-reports-section" className="mb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Cleaning Records</h3>
            
            <div className="mb-4">
              <label htmlFor="task" className="block text-gray-600 mb-2">Select Task:</label>
              <select id="task" value={selectedTask} onChange={handleTaskChange} className="p-2 border border-gray-300 rounded w-full">
                <option value="">All Tasks</option>
                {taskOptions.map((task, index) => (
                  <option key={index} value={task}>{task}</option>
                ))}
              </select>
            </div>
            
            {renderTableLayout(filterReports(groupedCleaningReports, 'cleaning'), 'cleaning', cleaningLimit, setCleaningLimit)}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ReportsPage;