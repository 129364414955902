//src/context/ThemeContext.jsx
import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [headerColour, setHeaderColour] = useState('#333333');
  const [navbarColour, setNavbarColour] = useState('#333333');

  useEffect(() => {
    const fetchColours = async () => {
      try {
        const [headerRes, navbarRes] = await Promise.all([
          fetch('/api/theme-colour'),
          fetch('/api/navbar-colour'),
        ]);

        const headerData = await headerRes.json();
        const navbarData = await navbarRes.json();

        if (headerRes.ok && headerData.colour) setHeaderColour(headerData.colour);
        if (navbarRes.ok && navbarData.colour) setNavbarColour(navbarData.colour);
      } catch (error) {
        console.error('Error fetching colours:', error);
      }
    };
    fetchColours();
  }, []);

  const updateHeaderColour = async (newColour) => {
    setHeaderColour(newColour);
    try {
      await fetch('/api/theme-colour', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ colour: newColour }),
      });
    } catch (error) {
      console.error('Error saving header colour:', error);
    }
  };

  const updateNavbarColour = async (newColour) => {
    setNavbarColour(newColour);
    try {
      await fetch('/api/navbar-colour', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ colour: newColour }),
      });
    } catch (error) {
      console.error('Error saving navbar colour:', error);
    }
  };

  return (
    <ThemeContext.Provider value={{ headerColour, updateHeaderColour, navbarColour, updateNavbarColour }}>
      {children}
    </ThemeContext.Provider>
  );
};