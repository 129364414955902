import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const DiaryPage = () => {
  const [noteContent, setNoteContent] = useState('');
  const [userName, setUserName] = useState('');
  const [priority, setPriority] = useState('Normal');
  const [notes, setNotes] = useState([]);

  //Function to fetch notes from the server
  const fetchNotes = async () => {
    try {
      const response = await fetch('/api/diaryNotes');
      if (response.ok) {
        const data = await response.json();
        setNotes(data);
      } else {
        console.error('Error fetching notes');
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  //Function to add a new note
  const addNote = async (e) => {
    e.preventDefault();
    if (!noteContent.trim() || !userName.trim()) {
      alert('Please enter your name and content for the note.');
      return;
    }

    const newNote = {
      content: noteContent.trim(),
      user: userName.trim(),
      priority,
      date: new Date().toISOString(),
    };

    try {
      const response = await fetch('/api/diaryNotes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newNote),
      });

      if (response.ok) {
        setNoteContent('');
        setUserName('');
        setPriority('Normal');
        await fetchNotes();
      } else {
        alert('Error adding note');
      }
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  //Function to delete a note from the server
  const deleteNote = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const response = await fetch(`/api/diaryNotes/${noteId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          await fetchNotes();
        } else {
          alert('Error deleting note');
        }
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    }
  };

  //Format date function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString;
    }
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  //Fetch notes on component mount
  useEffect(() => {
    fetchNotes();
  }, []);

  //Render notes with priority and user display
  const renderNotes = () => {
    if (notes.length === 0) {
      return <p>No notes available.</p>;
    }

    return notes
      .slice()
      .reverse()
      .map((note) => (
        <div key={note.id} className="p-4 mb-4 bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-semibold">{note.user}</p>
              <p className="text-sm text-gray-500">{formatDate(note.date)}</p>
            </div>
            <span
              className={`px-2 py-1 rounded-md text-xs font-bold ${
                note.priority === 'High'
                  ? 'bg-red-500 text-white'
                  : note.priority === 'Medium'
                  ? 'bg-yellow-500 text-white'
                  : 'bg-green-500 text-white'
              }`}
            >
              {note.priority}
            </span>
          </div>
          <p className="mt-4 text-gray-800">{note.content}</p>
          <button
            className="delete-btn bg-red-500 text-white py-1 px-3 rounded-md mt-2 hover:bg-red-600"
            onClick={() => deleteNote(note.id)}
          >
            Delete
          </button>
        </div>
      ));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="Notebook" />
      <Navbar />
      
      <main className="flex-1 p-8">
        <section id="notebook-section" className="mb-8">
          <h2 className="text-2xl font-bold mb-6">Your Notes</h2>
          <form onSubmit={addNote} className="mb-8 space-y-4">
            <input
              type="text"
              id="user-name"
              className="w-full p-4 border border-gray-300 rounded-md shadow-sm"
              placeholder="Your Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            <textarea
              id="note-content"
              className="w-full p-4 border border-gray-300 rounded-md shadow-sm"
              placeholder="Write your note here..."
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              required
            />
            <div>
              <label htmlFor="priority" className="block text-gray-700 font-medium">Priority</label>
              <select
                id="priority"
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              >
                <option value="Normal">Normal</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>
            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
            >
              Add Note
            </button>
          </form>
          <div id="notes-container">
            {renderNotes()}
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default DiaryPage;