//src/pages/CustomisationPage.jsx
import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QRCode from 'react-qr-code';
import { jsPDF } from 'jspdf';
import * as QRCodeGenerator from 'qrcode';
import { ThemeContext } from '../context/ThemeContext';

const CustomisationPage = () => {
  const { headerColour, updateHeaderColour, navbarColour, updateNavbarColour } = useContext(ThemeContext);
  const [tempHeaderColour, setTempHeaderColour] = useState(headerColour);
  const [tempNavbarColour, setTempNavbarColour] = useState(navbarColour);
  const [logoStatus, setLogoStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  useEffect(() => {
    setTempHeaderColour(headerColour);
    setTempNavbarColour(navbarColour);
  }, [headerColour, navbarColour]);

  const handleHeaderColourChange = (event) => {
    setTempHeaderColour(event.target.value.slice(0, 7));
  };

  const handleNavbarColourChange = (event) => {
    setTempNavbarColour(event.target.value.slice(0, 7));
  };

  const saveHeaderColourChange = () => {
    updateHeaderColour(tempHeaderColour);
  };

  const saveNavbarColourChange = () => {
    updateNavbarColour(tempNavbarColour);
  };

  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  const dynamicUrl = `https://${hostname}/menu`;

  const handleGenerateQRCode = () => {
    setQrCodeUrl(dynamicUrl);
  };

  const handleDownloadPdf = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
  
    //Load the main logo image (left-aligned)
    const mainLogoImage = await fetch('/EHO-Logo.png').then((res) => res.blob());
    const mainLogoReader = new FileReader();
    
    mainLogoReader.readAsDataURL(mainLogoImage);
    mainLogoReader.onloadend = async () => {
      const mainLogoBase64 = mainLogoReader.result;
  
      //Load the uploaded logo (right-aligned)
      const uploadedLogoUrl = '/api/logo'; //Change this to your uploaded logo endpoint
      const uploadedLogoImage = await fetch(uploadedLogoUrl).then((res) => res.blob());
      const uploadedLogoReader = new FileReader();
  
      uploadedLogoReader.readAsDataURL(uploadedLogoImage);
      uploadedLogoReader.onloadend = async () => {
        const uploadedLogoBase64 = uploadedLogoReader.result;
  
        //Add logos to the PDF
        const logoSize = 30;
        doc.addImage(mainLogoBase64, 'PNG', margin, 10, logoSize, logoSize); //Left-aligned logo
        doc.addImage(uploadedLogoBase64, 'PNG', pageWidth - margin - logoSize, 10, logoSize, logoSize); //Right-aligned logo
  
        //Title in the center
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(16);
        doc.setTextColor(40, 40, 40);
        doc.text('Menu QR Code', pageWidth / 2, 30, { align: 'center' });
  
        //Decorative Line Separator
        doc.setDrawColor(200, 200, 200);
        doc.setLineWidth(0.5);
        doc.line(margin, 45, pageWidth - margin, 45);
  
        //QR Code Section with Instructions
        QRCodeGenerator.toDataURL(qrCodeUrl).then((qrCodeDataUrl) => {
          const qrCodeSize = 100;
          const qrCodeX = (pageWidth - qrCodeSize) / 2;
    
          doc.addImage(qrCodeDataUrl, 'PNG', qrCodeX, 60, qrCodeSize, qrCodeSize);
  
          //QR Code URL and Instructions
          doc.setFontSize(14);
          doc.setTextColor(30, 30, 30);
          doc.text('Scan this QR Code to access the menu:', pageWidth / 2, 170, { align: 'center' });
          doc.setFontSize(12);
          doc.setTextColor(100, 100, 250);
          doc.text(dynamicUrl, pageWidth / 2, 180, { align: 'center' });
  
          //Footer Section
          doc.setFontSize(10);
          doc.setTextColor(150, 150, 150);
          doc.text('Thank you for using our service!', pageWidth / 2, 260, { align: 'center' });
          doc.text('Generated by EHO Tool', pageWidth / 2, 270, { align: 'center' });
  
          //Save the PDF
          doc.save(`${subdomain}_menu_qr_code.pdf`);
        });
      };
    };
  };

  const uploadLogo = async (event) => {
    event.preventDefault();
    const logoFile = event.target.elements['logo-upload'].files[0];
    if (logoFile) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('logo', logoFile);
      try {
        const response = await fetch('/api/uploadLogo', {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        setLogoStatus(response.ok ? 'Logo uploaded successfully!' : `Error uploading logo: ${data.error}`);
      } catch {
        setLogoStatus('Error uploading logo.');
      } finally {
        setIsUploading(false);
      }
    } else {
      setLogoStatus('Please select a logo file to upload.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header title="Customisation Page" headerColour={tempHeaderColour} />
      <Navbar />

      <main className="flex-1 p-8">
        <section className="bg-white p-8 mb-8 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold">Theme Settings</h2>

            <div className="mt-4">
              <label htmlFor="header-colour-picker" className="block text-gray-700">Header Colour:</label>
              <input
                type="color"
                id="header-colour-picker"
                value={tempHeaderColour}
                onChange={handleHeaderColourChange}
                className="w-16 h-8 mt-2"
              />
              <button onClick={saveHeaderColourChange} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md block">
                Save Header Colour
              </button>
            </div>

            <div className="mt-6">
              <label htmlFor="navbar-colour-picker" className="block text-gray-700">Navbar Colour:</label>
              <input
                type="color"
                id="navbar-colour-picker"
                value={tempNavbarColour}
                onChange={handleNavbarColourChange}
                className="w-16 h-8 mt-2"
              />
              <button onClick={saveNavbarColourChange} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md block">
                Save Navbar Colour
              </button>
            </div>
          </section>

        <section className="bg-white p-8 mb-8 shadow-md rounded-lg">
          <h2 className="text-xl font-semibold mb-6">Generate Menu QR Code</h2>
          <button onClick={handleGenerateQRCode} className="bg-blue-500 text-white py-2 px-4 rounded-md">Generate QR Code for {subdomain}</button>
          {qrCodeUrl && (
            <div className="mt-6">
              <QRCode value={qrCodeUrl} size={200} />
              <p className="mt-4">{qrCodeUrl}</p>
              <button onClick={handleDownloadPdf} className="bg-green-500 text-white py-2 px-4 mt-4 rounded-md">Download QR Code as PDF</button>
            </div>
          )}
        </section>

        <section className="bg-white p-8 mb-8 shadow-md rounded-lg">
          <h2 className="text-2xl font-semibold mb-6">Upload Logo</h2>
          <form onSubmit={uploadLogo}>
            <input type="file" id="logo-upload" name="logo-upload" required className="w-full p-2 border rounded-md" />
            <button type="submit" className={`w-full py-2 rounded-md ${isUploading ? 'bg-gray-400' : 'bg-indigo-500 text-white hover:bg-indigo-600'}`} disabled={isUploading}>
              {isUploading ? 'Uploading...' : 'Upload Logo'}
            </button>
            <p className="text-sm text-gray-500 mt-2">{logoStatus}</p>
          </form>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default CustomisationPage;