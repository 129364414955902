import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import moment from 'moment-timezone';

const CleaningPage = () => {
  const [cleaningTasks, setCleaningTasks] = useState([]);
  const [cleaningRecords, setCleaningRecords] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState('');

  //Fetch cleaning tasks and records from the server
  useEffect(() => {
    const fetchCleaningTasks = async () => {
      const response = await fetch(`/api/cleaningTasks`);
      const data = await response.json();
      setCleaningTasks(data);
    };
    const fetchCleaningRecords = async () => {
      const response = await fetch(`/api/cleaningRecords`);
      const data = await response.json();
      setCleaningRecords(data);
    };

    fetchCleaningTasks();
    fetchCleaningRecords();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment().tz('Europe/London').format('DD/MM/YYYY h:mm:ss A'));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  //Handle form submission to save a cleaning record
  const handleFormSubmit = async (task, cleanerName) => {
    const currentTimestamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');

    const cleaningRecord = {
      task_name: task.task_name,
      cleaner_name: cleanerName,
      record_timestamp: currentTimestamp,
    };

    const response = await fetch('/api/cleaningRecords', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cleaningRecord),
    });

    if (response.ok) {
      alert('Cleaning recorded successfully!');
      const updatedRecordsResponse = await fetch('/api/cleaningRecords');
      const updatedRecords = await updatedRecordsResponse.json();
      setCleaningRecords(updatedRecords);
    } else {
      alert('Error saving cleaning record');
    }
  };

  //Helper function to format date and time
  const formatDateTime = (dateTimeString) => {
    return moment.utc(dateTimeString).tz('Europe/London').format('DD/MM/YYYY h:mm:ss A');
  };

  const getLastSubmittedRecord = (taskName) => {
    const taskRecords = cleaningRecords.filter((record) => record.task_name === taskName);
    if (taskRecords.length > 0) {
      const latestRecord = taskRecords.sort((a, b) => new Date(b.record_timestamp) - new Date(a.record_timestamp))[0];
      return latestRecord;
    }
    return null;
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="Cleaning Records" />
      <Navbar />

      <main className="flex-1 p-8">
        <section className="bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Record Cleaning</h2>

          <p className="text-lg font-semibold mb-4">
            Current Date and Time: {currentDateTime}
          </p>

          <div>
            {cleaningTasks.length > 0 ? (
              cleaningTasks.map((task) => (
                <form
                  key={task.id}
                  className="mb-6 p-6 bg-gray-50 rounded-lg shadow-md"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const cleanerName = e.target.elements[`cleaner-name-${task.task_name}`].value.trim();
                    handleFormSubmit(task, cleanerName);
                    e.target.reset();
                  }}
                >
                  <h3 className="text-lg font-bold mb-2">{task.task_name}</h3>

                  <div className="mb-4">
                    <label htmlFor={`cleaner-name-${task.task_name}`} className="block font-medium mb-1">Cleaner Name:</label>
                    <input
                      type="text"
                      id={`cleaner-name-${task.task_name}`}
                      name="cleanerName"
                      required
                      className="p-2 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <button type="submit" className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600 w-full">
                    Submit
                  </button>

                  {/* Display last submitted time for this task */}
                  <p className="text-sm text-gray-600 mt-2">
                    Last submitted: 
                    {getLastSubmittedRecord(task.task_name)
                      ? formatDateTime(getLastSubmittedRecord(task.task_name).record_timestamp)
                      : 'No record yet'}
                  </p>
                </form>
              ))
            ) : (
              <p>No cleaning tasks available.</p>
            )}
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default CleaningPage;