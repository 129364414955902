//src/pages/ProtectedRoute.jsx
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, requiredRole }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/check-session', {
          credentials: 'include',
        });
        const data = await response.json();
        
        if (data.isAuthenticated) {
          setIsAuthenticated(true);
          setUserRole(data.role);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) {
    //Show a loading indicator while the authentication status is being fetched
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    //Redirect to login page if the user is not authenticated
    return <Navigate to="/login" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    //If the user does not have the required role, redirect to a "Not Authorized" page
    return <Navigate to="/not-authorized" />;
  }

  //If the user is authenticated and has the correct role, render the protected content
  return children;
};

export default ProtectedRoute;
