import React, { useState } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const WeeklyReviewPage = () => {
  const [formData, setFormData] = useState({
    week: '',
    serious_problem: '',
    problem_details: '',
    action_taken: '',
    new_staff: '',
    staff_training: '',
    menu_changed: '',
    new_methods: '',
    supplier_changed: '',
    other_changes: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('/api/weeklyReviews', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Review submitted successfully!');
      setFormData({
        week: '',
        serious_problem: '',
        problem_details: '',
        action_taken: '',
        new_staff: '',
        staff_training: '',
        menu_changed: '',
        new_methods: '',
        supplier_changed: '',
        other_changes: ''
      });
    } else {
      alert('Error submitting review. Please try again.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="4-Weekly Review" />
      <Navbar />

      <main className="flex-1 p-8">
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-2xl font-bold text-gray-700 mb-6">4-Weekly Review</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-4">
              <label htmlFor="week" className="block font-semibold">Week Commencing:</label>
              <input
                type="date"
                id="week"
                name="week"
                value={formData.week}
                onChange={handleChange}
                className="border rounded-md p-2 w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block font-semibold">Did you have a serious problem or did the same thing go wrong three times or more?</label>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="serious_problem"
                    value="yes"
                    onChange={handleChange}
                    className="form-radio"
                    checked={formData.serious_problem === 'yes'}
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center ml-4">
                  <input
                    type="radio"
                    name="serious_problem"
                    value="no"
                    onChange={handleChange}
                    className="form-radio"
                    checked={formData.serious_problem === 'no'}
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="problem-details" className="block font-semibold">Details:</label>
              <textarea
                id="problem-details"
                name="problem_details"
                value={formData.problem_details}
                onChange={handleChange}
                rows="4"
                className="border rounded-md p-2 w-full"
              ></textarea>
            </div>

            <div className="mb-4">
              <label htmlFor="action-taken" className="block font-semibold">What did you do about it?</label>
              <textarea
                id="action-taken"
                name="action_taken"
                value={formData.action_taken}
                onChange={handleChange}
                rows="4"
                className="border rounded-md p-2 w-full"
              ></textarea>
            </div>

            <div className="mb-4">
              <label className="block font-semibold">New Staff in the past 4 weeks?</label>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="new_staff"
                    value="yes"
                    onChange={handleChange}
                    className="form-radio"
                    checked={formData.new_staff === 'yes'}
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center ml-4">
                  <input
                    type="radio"
                    name="new_staff"
                    value="no"
                    onChange={handleChange}
                    className="form-radio"
                    checked={formData.new_staff === 'no'}
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label className="block font-semibold">Were they trained in your methods?</label>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="staff_training"
                    value="yes"
                    onChange={handleChange}
                    className="form-radio"
                    checked={formData.staff_training === 'yes'}
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center ml-4">
                  <input
                    type="radio"
                    name="staff_training"
                    value="no"
                    onChange={handleChange}
                    className="form-radio"
                    checked={formData.staff_training === 'no'}
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label className="block font-semibold">Any other changes?</label>
              <textarea
                id="other-changes"
                name="other_changes"
                value={formData.other_changes}
                onChange={handleChange}
                rows="4"
                className="border rounded-md p-2 w-full"
              ></textarea>
            </div>

            <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-md">
              Submit Review
            </button>
          </form>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default WeeklyReviewPage;