import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import moment from 'moment-timezone';

const TemperaturePage = () => {
  const [refrigerationSystems, setRefrigerationSystems] = useState([]);
  const [temperatureReports, setTemperatureReports] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [name, setName] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState('');

  //Fetch refrigeration systems and temperature reports on component mount
  useEffect(() => {
    const fetchRefrigerationSystems = async () => {
      const response = await fetch('/api/refrigerationSystems');
      if (response.ok) {
        const data = await response.json();
        setRefrigerationSystems(data);
      } else {
        console.error('Error fetching refrigeration systems');
      }
    };

    const fetchTemperatureReports = async () => {
      const response = await fetch('/api/temperatureReports');
      if (response.ok) {
        const data = await response.json();
        setTemperatureReports(data);
      } else {
        console.error('Error fetching temperature reports');
      }
    };

    fetchRefrigerationSystems();
    fetchTemperatureReports();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment().tz('Europe/London').format('DD/MM/YYYY h:mm:ss A'));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  //Handle temperature input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  //Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    //Format the date to 'YYYY-MM-DD HH:mm:ss'
    const formattedDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  
    for (const system of refrigerationSystems) {
      const temperatureValue = formValues[system.system_name];
      if (temperatureValue) {
        const temperatureRecord = {
          report_date: formattedDate,
          system_name: system.system_name,
          system_type: system.system_type,
          temperature: temperatureValue,
          name: name,
        };
  
        //Submit each temperature record
        const response = await fetch('/api/temperatureReports', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(temperatureRecord),
        });
  
        if (!response.ok) {
          alert(`Error submitting temperature report for ${system.system_name}`);
          return;
        }
      }
    }
  
    //Re-fetch the temperature reports to update the "last submitted" time
    const updatedReportsResponse = await fetch('/api/temperatureReports');
    const updatedReports = await updatedReportsResponse.json();
    setTemperatureReports(updatedReports);
  
    window.location.href = '/reports';
  };

  //Helper function to format date and time
  const formatDateTime = (dateTimeString) => {
    return moment.utc(dateTimeString).tz('Europe/London').format('DD/MM/YYYY h:mm:ss A');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
  };

  //Render the refrigeration systems input fields along with the last submission time
  const renderRefrigerationInputs = () => {
    const groupedSystems = refrigerationSystems.reduce((acc, system) => {
      acc[system.system_type] = acc[system.system_type] || [];
      acc[system.system_type].push(system);
      return acc;
    }, {});

    return Object.keys(groupedSystems).map((type) => (
      <div key={type}>
        <h3 className="font-bold text-lg mt-4">{type}</h3>
        {groupedSystems[type].map((system) => (
          <div key={system.system_name} className="mb-4">
            <label htmlFor={system.system_name} className="block font-semibold">
              {system.system_name} ({system.system_type}):
            </label>
            <input
              type="number"
              id={system.system_name}
              name={system.system_name}
              className="border rounded-md p-2 mt-2"
              value={formValues[system.system_name] || ''}
              onChange={handleInputChange}
              required
            />

            {/* Display last submitted time for this system */}
            <p className="text-sm text-gray-600 mt-2">
              Last submitted: 
              {temperatureReports
                .filter(report => report.system_name === system.system_name)
                .sort((a, b) => new Date(b.report_date) - new Date(a.report_date))
                .map(report => formatDate(report.report_date))[0] || 'No record yet'}
            </p>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="Temperature Recording" />
      <Navbar />

      <main className="flex-1 p-8">
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-2xl font-bold text-gray-700 mb-6">
            Record Fridge and Freezer Temperatures
          </h2>

          <p className="text-lg font-semibold mb-4">
            Current Date and Time: {currentDateTime}
          </p>

          <form onSubmit={handleSubmit}>
            {renderRefrigerationInputs()}

            <div className="mb-4">
              <label htmlFor="name" className="block font-semibold">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                className="border rounded-md p-2 mt-2 w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-md">
              Submit
            </button>
          </form>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default TemperaturePage;