import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';

const Header = ({ title }) => {
  const [logoUrl, setLogoUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { headerColour } = useContext(ThemeContext); // Use headerColour from ThemeContext

  useEffect(() => {
    const fetchLogo = () => {
      const url = '/api/logo?' + Date.now();
      setLoading(true);
      setError(false);
      
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Logo not found');
          }
          setLogoUrl(url);
        })
        .catch((err) => {
          console.error('Error fetching logo:', err);
          setError(true);
        })
        .finally(() => setLoading(false));
    };

    fetchLogo();

    const updateLogo = () => {
      fetchLogo();
    };
    window.addEventListener('logoUpdated', updateLogo);

    return () => {
      window.removeEventListener('logoUpdated', updateLogo);
    };
  }, []);

  return (
    <header style={{ backgroundColor: headerColour }} className="text-white p-4 flex items-center justify-between">
      <div className="flex items-center">
        {loading ? (
          <span className="text-3xl font-bold">Loading...</span>
        ) : error ? (
          <span className="text-3xl font-bold">Error loading logo</span>
        ) : logoUrl ? (
          <Link to="/">
            <img
              src={logoUrl}
              alt="Logo"
              className="h-20 w-auto"
            />
          </Link>
        ) : (
          <Link to="/">
            <span className="text-3xl font-bold">Default Logo</span>
          </Link>
        )}
      </div>
      <h1 className="text-3xl font-bold text-right">{title}</h1>
    </header>
  );
};

export default Header;