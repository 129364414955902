//src/components/Navbar.jsx
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { navbarColour } = useContext(ThemeContext);
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    if (!window.confirm('Are you sure you want to logout?')) return;
    try {
      const response = await fetch('/api/logout', { method: 'GET', credentials: 'include' });
      if (response.ok && (await response.json()).success) {
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <nav style={{ backgroundColor: navbarColour }} className="text-white p-4">
      <div className="max-w-full flex justify-between items-center">
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex flex-grow justify-end space-x-6">
          {/* Other links */}
          <li><a href="/temperature" className="hover:bg-gray-900 p-2 rounded">Temperature Recording</a></li>
          <li><a href="/allergens" className="hover:bg-gray-900 p-2 rounded">Allergen Advice</a></li>
          <li><a href="/staff" className="hover:bg-gray-900 p-2 rounded">Staff Training</a></li>
          <li><a href="/cleaning" className="hover:bg-gray-900 p-2 rounded">Cleaning Records</a></li>
          <li><a href="/reports" className="hover:bg-gray-900 p-2 rounded">Reports</a></li>
          <li><a href="/notes" className="hover:bg-gray-900 p-2 rounded">Notes</a></li>
          <li><a href="/admin" className="hover:bg-gray-900 p-2 rounded">Admin</a></li>
          <li><a href="/customisation" className="hover:bg-gray-900 p-2 rounded">Customisation</a></li>
          <li>
            <button onClick={handleLogout} className="hover:bg-gray-900 p-2 rounded focus:outline-none">Logout</button>
          </li>
        </ul>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <ul className="lg:hidden mt-4 space-y-2">
          <li><a href="/temperature" className="block hover:bg-gray-900 p-2 rounded">Temperature Recording</a></li>
          <li><a href="/allergens" className="block hover:bg-gray-900 p-2 rounded">Allergen Advice</a></li>
          <li><a href="/staff" className="block hover:bg-gray-900 p-2 rounded">Staff Training</a></li>
          <li><a href="/cleaning" className="block hover:bg-gray-900 p-2 rounded">Cleaning Records</a></li>
          <li><a href="/reports" className="block hover:bg-gray-900 p-2 rounded">Reports</a></li>
          <li><a href="/notes" className="block hover:bg-gray-900 p-2 rounded">Notes</a></li>
          <li><a href="/admin" className="block hover:bg-gray-900 p-2 rounded">Admin</a></li>
          <li><a href="/customisation" className="block hover:bg-gray-900 p-2 rounded">Customisation</a></li>
          <li>
            <button onClick={handleLogout} className="block hover:bg-gray-900 p-2 rounded w-full text-left">Logout</button>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;