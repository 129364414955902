import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

//Import allergen icons here
import GlutenIcon from '../assets/gluten.png';
import DairyIcon from '../assets/milk.png';
import NutsIcon from '../assets/nuts.png';
import PeanutsIcon from '../assets/peanuts.png';
import SoyaIcon from '../assets/soya.png';
import FishIcon from '../assets/fish.png';
import CrustaceansIcon from '../assets/crustaceans.png';
import EggsIcon from '../assets/eggs.png';
import SesameIcon from '../assets/sesame.png';
import CeleryIcon from '../assets/celery.png';
import MustardIcon from '../assets/mustard.png';
import LupinIcon from '../assets/lupin.png';
import SulphitesIcon from '../assets/so2.png';
import MolluscsIcon from '../assets/molluscs.png';

const AllergenPage = () => {
  const [dishRecords, setDishRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [excludedAllergens, setExcludedAllergens] = useState([]);

  const allergenIcons = {
    Gluten: GlutenIcon,
    Dairy: DairyIcon,
    Nuts: NutsIcon,
    Peanuts: PeanutsIcon,
    Soya: SoyaIcon,
    Fish: FishIcon,
    Crustaceans: CrustaceansIcon,
    Eggs: EggsIcon,
    Sesame: SesameIcon,
    Celery: CeleryIcon,
    Mustard: MustardIcon,
    Lupin: LupinIcon,
    Sulphites: SulphitesIcon,
    Molluscs: MolluscsIcon
  };

  useEffect(() => {
    const fetchDishes = async () => {
      const response = await fetch(`/api/dishes`);
      const data = await response.json();
      setDishRecords(data);
    };
    fetchDishes();
  }, []);

  const handleAllergenClick = (allergenName) => {
    const normalisedAllergen = allergenName.toLowerCase();
    if (excludedAllergens.includes(normalisedAllergen)) {
      setExcludedAllergens(excludedAllergens.filter(a => a !== normalisedAllergen));
    } else {
      setExcludedAllergens([...excludedAllergens, normalisedAllergen]);
    }
  };

  const renderDishes = () => {
    const filteredDishes = dishRecords.filter(dish => {
      const allergens = Array.isArray(dish.allergens)
        ? dish.allergens.map(a => a.toLowerCase().trim())
        : (dish.allergens || '').split(',').map(a => a.toLowerCase().trim());
  
      const hasExcludedAllergen = allergens.some(allergen => excludedAllergens.includes(allergen));
  
      if (hasExcludedAllergen) return false;
  
      if (searchTerm) {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return dish.dish_name.toLowerCase().includes(lowerCaseSearchTerm) || dish.ingredients.toLowerCase().includes(lowerCaseSearchTerm);
      }
  
      return true;
    });
  
    return filteredDishes.map((dish) => (
      <li key={dish.id} className="mb-4 p-4 bg-white rounded-lg shadow">
        <h3 className="text-xl font-bold">{dish.dish_name}</h3>
        <p className="mt-2"><strong>Ingredients:</strong> {dish.ingredients}</p>
        <div className="mt-2 flex space-x-2 items-center">
          <strong>Allergens:</strong>
          {dish.allergens.length === 0 || dish.allergens.includes("None") ? (
            <span className="text-gray-600">None</span>
          ) : (
            dish.allergens.map((allergen) => (
              <img key={allergen} src={allergenIcons[allergen]} alt={allergen} className="w-10 h-10" />
            ))
          )}
        </div>
        <div className="mt-2">
          {dish.vegetarian && <span className="bg-green-200 text-green-800 py-1 px-2 rounded">Vegetarian</span>}
          {dish.vegan && <span className="bg-yellow-200 text-yellow-800 py-1 px-2 rounded">Vegan</span>}
        </div>
      </li>
    ));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="Allergen Advice" />
      <Navbar />
      
      <main className="flex-1 p-8">
        <section className="mb-6">
          <h1 className="text-2xl font-bold text-gray-700 mb-6">Allergen Advice</h1>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {Object.keys(allergenIcons).map(allergen => (
              <div
                key={allergen}
                className={`icon cursor-pointer p-4 border rounded-lg text-center ${excludedAllergens.includes(allergen.toLowerCase()) ? 'bg-red-200' : 'bg-white'}`}
                onClick={() => handleAllergenClick(allergen)}
              >
                <img src={allergenIcons[allergen]} alt={allergen} className="w-16 h-16 mx-auto mb-2" />
                <p className="text-gray-700">{allergen}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">Search Menu</h2>
          <input
            type="text"
            className="p-3 w-full rounded-md border border-gray-300 shadow-sm"
            placeholder="Search for dishes or ingredients..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </section>

        <section>
          <h2 className="text-xl font-bold mb-4">Allergen Information</h2>
          <ul className="list-none">
            {renderDishes()}
          </ul>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default AllergenPage;