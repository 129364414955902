//src/pages/Admin.jsx
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import moment from 'moment-timezone';
import QRCode from 'react-qr-code';
import { jsPDF } from 'jspdf';
import * as QRCodeGenerator from 'qrcode'; 

const Admin = () => {
  const [headerColour, setHeaderColour] = useState('#333333');
  const [refrigerationSystems, setRefrigerationSystems] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [temperatureReports, setTemperatureReports] = useState([]);
  const [cleaningTasks, setCleaningTasks] = useState([]);
  const [cleaningRecords, setCleaningRecords] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [name, setName] = useState('');
  const [fileError, setFileError] = useState('');

  //Visible counts for 'Load More' functionality
  const [visibleRefrigerationCount, setVisibleRefrigerationCount] = useState(5);
  const [visibleStaffCount, setVisibleStaffCount] = useState(5);
  const [visibleDishCount, setVisibleDishCount] = useState(5);
  const [visibleCleaningTaskCount, setVisibleCleaningTaskCount] = useState(5);
  const [visibleTemperatureCount, setVisibleTemperatureCount] = useState({});
  const [visibleCleaningRecordCount, setVisibleCleaningRecordCount] = useState({});

  useEffect(() => {
    const fetchThemeColour = async () => {
      try {
        const response = await fetch('/api/theme-colour');
        const data = await response.json();
        if (response.ok) {
          setHeaderColour(data.colour || '#33333');
        }
      } catch (error) {
        console.error('Error fetching theme colour:', error);
      }
    };

    fetchThemeColour();
    fetchRefrigerationSystems();
    fetchStaffMembers();
    fetchDishes();
    fetchTemperatureReports();
    fetchCleaningTasks();
    fetchCleaningRecords();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmitManual = async (event) => {
    event.preventDefault();
    const formattedDate = selectedDate;

    for (const system of refrigerationSystems) {
      const temperatureValue = formValues[system.system_name];
      if (temperatureValue) {
        const temperatureRecord = {
          report_date: formattedDate,
          system_name: system.system_name,
          system_type: system.system_type,
          temperature: temperatureValue,
          name: name,
        };

        const response = await fetch('/api/temperatureReports/retrospective', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(temperatureRecord),
        });

        if (!response.ok) {
          alert(`Error submitting manual temperature report for ${system.system_name}`);
          return;
        }
      }
    }
    window.location.reload();
  };

  //'Load more' functions
  const loadMoreRefrigerationSystems = () => {
    setVisibleRefrigerationCount((prevCount) => prevCount + 5);
  };

  const loadMoreStaffMembers = () => {
    setVisibleStaffCount((prevCount) => prevCount + 5);
  };

  const loadMoreDishes = () => {
    setVisibleDishCount((prevCount) => prevCount + 5);
  };

  const loadMoreCleaningTasks = () => {
    setVisibleCleaningTaskCount((prevCount) => prevCount + 5);
  };

  const loadMoreTemperatureReports = (date) => {
    setVisibleTemperatureCount((prevState) => ({
      ...prevState,
      [date]: (prevState[date] || 5) + 5,
    }));
  };

  const loadMoreCleaningRecords = (date) => {
    setVisibleCleaningRecordCount((prevState) => ({
      ...prevState,
      [date]: (prevState[date] || 5) + 5,
    }));
  };

  const sortByDateDescending = (a, b) => new Date(b.report_date) - new Date(a.report_date);
  const sortRecordsByTimestampDescending = (a, b) => new Date(b.record_timestamp) - new Date(a.record_timestamp);

  //Function to format the date in 'dd-MM-yyyy' format
  const formatDateTime = (dateTimeString) => {
    return moment.utc(dateTimeString).tz('Europe/London').format('DD/MM/YYYY h:mm A');
  };

  const formatDate = (dateString, returnIso = false) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString;
    }
    if (returnIso) {
      return date.toISOString().split('T')[0];
    }
    //Return date in 'dd-MM-yyyy' format
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  //Group records by type
  const groupedRefrigerationSystems = refrigerationSystems.reduce((acc, system) => {
    if (!acc[system.system_type]) {
      acc[system.system_type] = [];
    }
    acc[system.system_type].push(system);
    return acc;
  }, {});

  const groupedTemperatureReports = temperatureReports
    .sort(sortByDateDescending)
    .reduce((acc, report) => {
      const reportDate = formatDate(report.report_date);
      if (!acc[reportDate]) {
        acc[reportDate] = [];
      }
      acc[reportDate].push(report);
      return acc;
    }, {});

  const groupedCleaningRecords = cleaningRecords
    .sort(sortRecordsByTimestampDescending)
    .reduce((acc, record) => {
      const recordDate = formatDate(record.record_timestamp);
      if (!acc[recordDate]) {
        acc[recordDate] = [];
      }
      acc[recordDate].push(record);
      return acc;
    }, {});

  //Fetch Refrigeration Systems
  const fetchRefrigerationSystems = async () => {
    try {
      const response = await fetch('/api/refrigerationSystems', {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setRefrigerationSystems(data);
      } else {
        console.error('Error fetching refrigeration systems:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching refrigeration systems:', error);
    }
  };

  //Add Refrigeration System
  const addRefrigerationSystem = async (event) => {
    event.preventDefault();
    const systemType = event.target.elements['system-type'].value;
    const systemName = event.target.elements['system-name'].value;

    if (systemName) {
      try {
        const response = await fetch('/api/refrigerationSystems', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ system_type: systemType, system_name: systemName }),
          credentials: 'include',
        });
        if (response.ok) {
          fetchRefrigerationSystems();
          event.target.reset();
        } else {
          console.error('Error adding refrigeration system:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error adding refrigeration system:', error);
      }
    }
  };

  //Fetch Staff Members
  const fetchStaffMembers = async () => {
    try {
      const response = await fetch('/api/staffMembers', {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setStaffMembers(data);
      } else {
        console.error('Error fetching staff members:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching staff members:', error);
    }
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024; //Max 2MB
  const ALLOWED_FILE_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];

  const addStaffMember = async (event) => {
    event.preventDefault();
    setFileError(''); //Clear previous errors
    const staffName = event.target.elements['staff-name'].value;
    const staffDocuments = event.target.elements['staff-documents'].files;

    for (let file of staffDocuments) {
      //Check file size
      if (file.size > MAX_FILE_SIZE) {
        setFileError(`File "${file.name}" exceeds the maximum size of 2MB.`);
        return;
      }
      
      //Check file type
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setFileError(`File "${file.name}" is not a supported file type. Allowed types are PDF, DOCX, JPEG, and PNG.`);
        return;
      }
    }

    if (staffName) {
      try {
        const formData = new FormData();
        formData.append('name', staffName);
        for (const file of staffDocuments) {
          formData.append('staffDocuments', file);
        }

        const response = await fetch('/api/staffMembers', {
          method: 'POST',
          body: formData,
          credentials: 'include',
        });

        if (response.ok) {
          //Refresh the page after adding the staff member
          window.location.reload();
        } else {
          console.error('Error adding staff member:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error adding staff member:', error);
      }
    }
  };

  //Similar size check for uploading additional documents for a staff member
  const addStaffDocuments = async (event, memberId) => {
    event.preventDefault();
    setFileError('');
    const staffDocuments = event.target.elements[`staff-documents-${memberId}`].files;

    for (let file of staffDocuments) {
      if (file.size > MAX_FILE_SIZE) {
        setFileError(`File "${file.name}" exceeds the maximum size of 2MB.`);
        return;
      }
    }

    try {
      const formData = new FormData();
      for (const file of staffDocuments) {
        formData.append('staffDocuments', file);
      }

      const response = await fetch(`/api/staffMembers/${memberId}/documents`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      if (response.ok) {
        fetchStaffMembers();
        event.target.reset();
      } else {
        console.error('Error adding documents:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding documents:', error);
    }
  };

  //Remove Document for Staff Member
  const removeStaffDocument = async (memberId, documentId) => {
    try {
      const response = await fetch(`/api/staffMembers/${memberId}/documents/${documentId}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        fetchStaffMembers();
      } else {
        console.error('Error removing document:', response.statusText);
      }
    } catch (error) {
      console.error('Error removing document:', error);
    }
  };

  //Fetch Dishes
  const fetchDishes = async () => {
    try {
      const response = await fetch('/api/dishes', {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setDishes(data);
      } else {
        console.error('Error fetching dishes:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching dishes:', error);
    }
  };

  //Delete Dish
  const deleteDish = async (dishId) => {
    try {
      const response = await fetch(`/api/dishes/${dishId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        //Refresh dishes after deletion
        fetchDishes();
      } else {
        console.error('Error deleting dish:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting dish:', error);
    }
  };

  const handleAllergenChange = (e) => {
    const allergenValue = e.target.value;
    if (e.target.checked) {
      //Add allergen if checked
      setAllergens((prev) => [...prev, allergenValue]);
    } else {
      //Remove allergen if unchecked
      setAllergens((prev) => prev.filter((allergen) => allergen !== allergenValue));
    }
  };

  //Add Dish
  const addDish = async (event) => {
    event.preventDefault();
    
    const dishName = event.target.elements['dish-name'].value.trim();
    const ingredients = event.target.elements['ingredients'].value.trim();
  
    if (!dishName) {
      console.error('Dish name is missing');
      return;
    }
    if (!ingredients) {
      console.error('Ingredients are missing');
      return;
    }
    if (allergens.length === 0) {
      console.error('At least one allergen must be selected');
      return;
    }
  
    try {
      const response = await fetch('/api/dishes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dish_name: dishName,
          ingredients: ingredients,
          allergens: allergens.join(', '),
          vegetarian: formValues.vegetarian || false,
          vegan: formValues.vegan || false,
        }),
        credentials: 'include',
      });
  
      if (response.ok) {
        fetchDishes();
        setAllergens([]);
        setFormValues({ ...formValues, vegetarian: false, vegan: false });
        event.target.reset();
      } else {
        console.error('Error adding dish:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error adding dish:', error);
    }
  };

  //Fetch Temperature Reports
  const fetchTemperatureReports = async () => {
    try {
      const response = await fetch('/api/temperatureReports', {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setTemperatureReports(data);
      } else {
        console.error('Error fetching temperature reports:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching temperature reports:', error);
    }
  };

  //Fetch Cleaning Tasks
  const fetchCleaningTasks = async () => {
    try {
      const response = await fetch('/api/cleaningTasks', {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setCleaningTasks(data);
      } else {
        console.error('Error fetching cleaning tasks:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching cleaning tasks:', error);
    }
  };

  //Fetch cleaning records
  const fetchCleaningRecords = async () => {
    try {
      const response = await fetch('/api/cleaningRecords', {
        credentials: 'include',
      });
      const data = await response.json();
      setCleaningRecords(data);
    } catch (error) {
      console.error('Error fetching cleaning records:', error);
    }
  };

  //Add Cleaning Task
  const addCleaningTask = async (event) => {
    event.preventDefault();
    const taskName = event.target.elements['cleaning-task-name'].value;

    if (taskName) {
      try {
        const response = await fetch('/api/cleaningTasks', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ task_name: taskName }),
          credentials: 'include',
        });
        if (response.ok) {
          fetchCleaningTasks();
          event.target.reset();
        } else {
          console.error('Error adding cleaning task:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error adding cleaning task:', error);
      }
    }
  };

  //Delete staff member
  const deleteStaffMember = async (id) => {
    try {
      const response = await fetch(`/api/staffMembers/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchStaffMembers();
      } else {
        console.error('Error deleting staff member:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting staff member:', error);
    }
  };

  //Delete cleaning record
  const deleteCleaningRecord = async (id) => {
    try {
      const response = await fetch(`/api/cleaningRecords/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchCleaningRecords();
      } else {
        console.error('Error deleting cleaning record:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting cleaning record:', error);
    }
  };

  //Delete temperature report
  const deleteTemperatureReport = async (id) => {
    try {
      const response = await fetch(`/api/temperatureReports/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchTemperatureReports();
      } else {
        console.error('Error deleting temperature report:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting temperature report:', error);
    }
  };

  //Delete cleaning task
  const deleteCleaningTask = async (id) => {
    try {
      const response = await fetch(`/api/cleaningTasks/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchCleaningTasks();
      } else {
        console.error('Error deleting cleaning task:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting cleaning task:', error);
    }
  };

  const deleteRefrigerationSystem = async (id) => {
    try {
      const response = await fetch(`/api/refrigerationSystems/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchRefrigerationSystems();
      } else {
        console.error('Error deleting refrigeration system:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting refrigeration system:', error);
    }
  };

  //Delete all temperature reports for a specific date
  const deleteTemperatureReportsByDate = async (date) => {
    try {
      const reportsToDelete = groupedTemperatureReports[date];
      for (const report of reportsToDelete) {
        await deleteTemperatureReport(report.id);
      }
      fetchTemperatureReports();
    } catch (error) {
      console.error('Error deleting temperature reports by date:', error);
    }
  };

  //Delete all cleaning records for a specific date
  const deleteCleaningRecordsByDate = async (date) => {
    try {
      const recordsToDelete = groupedCleaningRecords[date];
      for (const record of recordsToDelete) {
        await deleteCleaningRecord(record.id);
      }
      fetchCleaningRecords();
    } catch (error) {
      console.error('Error deleting cleaning records by date:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header title="Admin Dashboard" headerColour={headerColour} />
      <Navbar />

      <main className="flex-1 p-8">
        {/* Quick Navigation Buttons */}
        <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <a href="#refrigeration-systems" className="bg-blue-500 text-white py-2 px-4 text-center rounded-md hover:bg-blue-600">
            Refrigeration Systems
          </a>
          <a href="#staff-management" className="bg-green-500 text-white py-2 px-4 text-center rounded-md hover:bg-green-600">
            Staff Management
          </a>
          <a href="#dishes-allergens" className="bg-red-500 text-white py-2 px-4 text-center rounded-md hover:bg-red-600">
            Dishes & Allergens
          </a>
          <a href="#temperature-reports" className="bg-purple-500 text-white py-2 px-4 text-center rounded-md hover:bg-purple-600">
            Temperature Reports
          </a>
          <a href="#cleaning-records" className="bg-yellow-500 text-white py-2 px-4 text-center rounded-md hover:bg-yellow-600">
            Cleaning Records
          </a>
          <a href="#cleaning-tasks" className="bg-orange-500 text-white py-2 px-4 text-center rounded-md hover:bg-orange-600">
            Cleaning Tasks
          </a>
        </div>

        {/* Refrigeration Systems */}
        <section id="refrigeration-systems" className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-blue-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">Refrigeration Systems</h2>
          <form onSubmit={addRefrigerationSystem} className="space-y-4">
            <div>
              <label htmlFor="system-type" className="block text-gray-600 font-medium">System Type</label>
              <select id="system-type" name="system-type" className="w-full p-2 border border-gray-300 rounded-md">
                <option value="Fridge">Fridge</option>
                <option value="Freezer">Freezer</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label htmlFor="system-name" className="block text-gray-600 font-medium">System Name</label>
              <input
                type="text"
                id="system-name"
                name="system-name"
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600">
              Add System
            </button>
          </form>

          <h3 className="text-lg font-semibold mt-8">Current Refrigeration Systems</h3>
          {Object.keys(groupedRefrigerationSystems).length ? (
            Object.keys(groupedRefrigerationSystems).map((type) => (
              <div key={type} className="mb-6">
                <h3 className="text-xl font-semibold text-blue-600">{type}</h3>
                <ul className="mt-4 list-disc pl-6 space-y-2">
                  {groupedRefrigerationSystems[type].slice(0, visibleRefrigerationCount).map((system) => (
                    <li key={system.id} className="flex justify-between">
                      <strong>{system.system_name}</strong>
                      <button
                        onClick={() => deleteRefrigerationSystem(system.id)}
                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </li>
                  ))}
                </ul>
                {groupedRefrigerationSystems[type].length > visibleRefrigerationCount && (
                  <button
                    onClick={() => loadMoreRefrigerationSystems(type)}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Load more {type}s
                  </button>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500">No refrigeration systems available.</p>
          )}
        </section>

        {/* Staff Management Section */}
        <section id="staff-management" className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-green-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">Staff Management</h2>
          
          {/* Display file size error message */}
          {fileError && <p className="text-red-500 mb-4">{fileError}</p>}

          <form onSubmit={addStaffMember} className="space-y-4">
            <div>
              <label htmlFor="staff-name" className="block text-gray-600 font-medium">Staff Name</label>
              <input
                type="text"
                id="staff-name"
                name="staff-name"
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label htmlFor="staff-documents" className="block text-gray-600 font-medium">Staff Documents</label>
              <input
                type="file"
                id="staff-documents"
                name="staff-documents"
                multiple
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <button type="submit" className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600">
              Add Staff Member
            </button>
          </form>

          <h3 className="text-lg font-semibold mt-8">Current Staff Members (refresh page to see recently uploaded documents):</h3>
          <ul className="mt-4 list-disc pl-6 space-y-4">
            {staffMembers.slice(0, visibleStaffCount).map((member) => (
              <li key={member.id} className="flex flex-col space-y-2 bg-gray-50 p-4 rounded-lg shadow-md">
                <div className="flex justify-between items-center">
                  <span className="font-bold">{member.name}</span>
                  <button
                    onClick={() => deleteStaffMember(member.id)}
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>

                {/* List of Uploaded Documents */}
                {member.documents && member.documents.length > 0 ? (
                  <div>
                    <h4 className="font-semibold text-gray-600">Staff Documents:</h4>
                    <ul className="list-disc pl-6 space-y-1">
                      {member.documents.map((doc) => (
                        <li key={doc.id} className="flex justify-between items-center">
                          <a href={doc.document_link} className="text-blue-600 hover:underline">
                            {doc.document_name}
                          </a>
                          <button
                            onClick={() => removeStaffDocument(member.id, doc.id)}
                            className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="text-gray-500">No documents uploaded yet.</p>
                )}

                {/* Add More Documents for the Staff Member */}
                <form onSubmit={(e) => addStaffDocuments(e, member.id)} className="mt-4">
                  <div className="flex items-center space-x-4">
                    <input
                      type="file"
                      id={`staff-documents-${member.id}`}
                      name={`staff-documents-${member.id}`}
                      multiple
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                    <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">
                      Upload
                    </button>
                  </div>
                </form>
              </li>
            ))}
          </ul>
          {visibleStaffCount < staffMembers.length && (
            <button
              onClick={loadMoreStaffMembers}
              className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
            >
              Load more
            </button>
          )}
        </section>

        {/* Dishes & Allergens */}
        <section id="dishes-allergens" className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-red-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">Dishes & Allergens</h2>
          <form onSubmit={addDish} className="space-y-4">
            <div>
              <label htmlFor="dish-name" className="block text-gray-600 font-medium">Dish Name</label>
              <input
                type="text"
                id="dish-name"
                name="dish-name"
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label htmlFor="ingredients" className="block text-gray-600 font-medium">Ingredients</label>
              <input
                type="text"
                id="ingredients"
                name="ingredients"
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label htmlFor="vegetarian" className="block text-gray-600 font-medium">Vegetarian</label>
              <input
                type="checkbox"
                id="vegetarian"
                name="vegetarian"
                checked={formValues.vegetarian || false}
                onChange={(e) => setFormValues({ ...formValues, vegetarian: e.target.checked })}
              />
            </div>
            <div>
              <label htmlFor="vegan" className="block text-gray-600 font-medium">Vegan</label>
              <input
                type="checkbox"
                id="vegan"
                name="vegan"
                checked={formValues.vegan || false}
                onChange={(e) => setFormValues({ ...formValues, vegan: e.target.checked })}
              />
            </div>
            <div>
              <label htmlFor="allergens" className="block text-gray-600 font-medium">Allergens</label>
              <div id="allergens" className="space-y-2">
                {['Gluten', 'Dairy', 'Nuts', 'Peanuts', 'Soya', 'Fish', 'Crustaceans', 'Eggs', 'Sesame', 'Celery', 'Mustard', 'Lupin', 'Sulphites', 'Molluscs', 'None'].map((allergen) => (
                  <div key={allergen} className="flex items-center">
                    <input
                      type="checkbox"
                      id={allergen}
                      name="allergens"
                      value={allergen}
                      checked={allergens.includes(allergen)}
                      onChange={handleAllergenChange}
                    />
                    <label htmlFor={allergen} className="ml-2">{allergen}</label>
                  </div>
                ))}
              </div>
            </div>
            <button type="submit" className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600">
              Add Dish
            </button>
          </form>

          <h3 className="text-lg font-semibold mt-8">Current Dishes</h3>
          <ul className="mt-4 list-disc pl-6 space-y-2">
            {dishes.length ? (
              dishes.slice(0, visibleDishCount).map((dish) => (
                <li key={dish.id} className="mb-4 flex justify-between items-center">
                  <div>
                    <strong>{dish.dish_name}</strong>
                    <span className="block mt-2 text-gray-600">
                      <strong>Ingredients:</strong> {dish.ingredients}
                    </span>
                    <span className="block mt-2 text-gray-600">
                      <strong>Allergens:</strong> {dish.allergens.join(', ')}
                    </span>
                  </div>
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    onClick={() => deleteDish(dish.id)}
                  >
                    Delete
                  </button>
                </li>
              ))
            ) : (
              <p className="text-gray-500">No dishes available.</p>
            )}
          </ul>

          {visibleDishCount < dishes.length && (
            <button
              onClick={loadMoreDishes}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            >
              Load more
            </button>
          )}
        </section>

        {/* Temperature Reports */}
        <section id="temperature-reports" className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-purple-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">Temperature Reports</h2>
          {Object.keys(groupedTemperatureReports).length ? (
            Object.keys(groupedTemperatureReports).map((date) => (
              <div key={date} className="mb-6">
                <h3 className="text-xl font-semibold text-purple-600">{formatDate(date)}</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b">System Name</th>
                        <th className="py-2 px-4 border-b">Temperature (°C)</th>
                        <th className="py-2 px-4 border-b">Submitted By</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedTemperatureReports[date]
                        .sort(sortByDateDescending)
                        .slice(0, visibleTemperatureCount[date] || 5)
                        .map((report) => (
                          <tr key={report.id} className="text-center">
                            <td className="py-2 px-4 border-b">{report.system_name}</td>
                            <td className="py-2 px-4 border-b">{report.temperature}</td>
                            <td className="py-2 px-4 border-b">{report.name}</td>
                            <td className="py-2 px-4 border-b">
                              <button
                                onClick={() => deleteTemperatureReport(report.id)}
                                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {groupedTemperatureReports[date].length > (visibleTemperatureCount[date] || 5) && (
                  <button
                    onClick={() => loadMoreTemperatureReports(date)}
                    className="mt-4 bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600"
                  >
                    Load more
                  </button>
                )}
                <div className="flex justify-end">
                  <button
                    onClick={() => deleteTemperatureReportsByDate(date)}
                    className="bg-red-500 text-white py-1 px-3 mt-4 rounded hover:bg-red-600"
                  >
                    Delete All Reports for {formatDate(date)}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No temperature reports available.</p>
          )}
        </section>

        {/* Manual Temperature Report Submission */}
        <section className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-purple-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">
            Manual Temperature Report Submission
          </h2>

          <form onSubmit={handleSubmitManual} className="space-y-6">
            <div>
              <label htmlFor="report-date" className="block font-medium text-gray-700">
                Select Date:
              </label>
              <input
                type="date"
                id="report-date"
                name="report-date"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                required
              />
            </div>

            <div>
              <label htmlFor="name" className="block font-medium text-gray-700">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            {refrigerationSystems.map((system) => (
              <div key={system.system_name} className="mb-4">
                <label htmlFor={system.system_name} className="block font-medium text-gray-700">
                  {system.system_name} ({system.system_type}):
                </label>
                <input
                  type="number"
                  id={system.system_name}
                  name={system.system_name}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={formValues[system.system_name] || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
            ))}

            <button
              type="submit"
              className="w-full bg-purple-500 text-white py-2 rounded-md hover:bg-purple-600"
            >
              Submit Manual Reports
            </button>
          </form>
        </section>

        {/* Cleaning Records */}
        <section id="cleaning-records" className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-yellow-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">Manage Cleaning Records</h2>
          {Object.keys(groupedCleaningRecords).length ? (
            Object.keys(groupedCleaningRecords).map((date) => (
              <div key={date} className="mb-6">
                <h3 className="text-xl font-semibold text-yellow-600">{date}</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b">Task Name</th>
                        <th className="py-2 px-4 border-b">Cleaner Name</th>
                        <th className="py-2 px-4 border-b">Timestamp</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedCleaningRecords[date]
                        .sort(sortRecordsByTimestampDescending)
                        .slice(0, visibleCleaningRecordCount[date] || 5)
                        .map((record) => (
                          <tr key={record.id} className="text-center">
                            <td className="py-2 px-4 border-b">{record.task_name}</td>
                            <td className="py-2 px-4 border-b">{record.cleaner_name}</td>
                            <td className="py-2 px-4 border-b">{formatDateTime(record.record_timestamp)}</td>
                            <td className="py-2 px-4 border-b">
                              <button
                                onClick={() => deleteCleaningRecord(record.id)}
                                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {groupedCleaningRecords[date].length > (visibleCleaningRecordCount[date] || 5) && (
                  <button
                    onClick={() => loadMoreCleaningRecords(date)}
                    className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
                  >
                    Load more
                  </button>
                )}
                <div className="flex justify-end">
                  <button
                    onClick={() => deleteCleaningRecordsByDate(date)}
                    className="bg-red-500 text-white py-1 px-3 mt-4 rounded hover:bg-red-600"
                  >
                    Delete All Records for {date}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No cleaning records available.</p>
          )}
        </section>

        {/* Cleaning Tasks */}
        <section id="cleaning-tasks" className="bg-white p-8 mb-8 shadow-md rounded-lg border-t-4 border-orange-500">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">Cleaning Tasks</h2>
          <form onSubmit={addCleaningTask} className="space-y-4">
            <div>
              <label htmlFor="cleaning-task-name" className="block text-gray-600 font-medium">Task Name</label>
              <input
                type="text"
                id="cleaning-task-name"
                name="cleaning-task-name"
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <button type="submit" className="w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
              Add Task
            </button>
          </form>
          <h3 className="text-lg font-semibold mt-8">Current Cleaning Tasks</h3>
          <ul className="mt-4 list-disc pl-6 space-y-2">
            {cleaningTasks.slice(0, visibleCleaningTaskCount).map((task) => (
              <li key={task.id} className="flex justify-between">
                <span>{task.task_name}</span>
                <button
                  onClick={() => deleteCleaningTask(task.id)}
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          {visibleCleaningTaskCount < cleaningTasks.length && (
            <button
              onClick={loadMoreCleaningTasks}
              className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
            >
              Load more
            </button>
          )}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Admin;