import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const StaffPage = () => {
  const [staffMembers, setStaffMembers] = useState([]);

  // Fetch staff members when the component mounts
  useEffect(() => {
    const fetchStaffMembers = async () => {
      const response = await fetch(`/api/staffMembers`);
      const data = await response.json();
      setStaffMembers(data);
    };
    fetchStaffMembers();
  }, []);

  // Function to render staff members
  const renderStaffMembers = () => {
    return staffMembers.map((member, index) => (
      <li key={index} className="mb-4 p-4 bg-white rounded-lg shadow">
        <h3 className="text-lg font-bold">{member.name}</h3>
        {member.image && (
          <img src={member.image} alt={`${member.name}'s Image`} className="w-32 h-32 object-cover mt-2 mb-4" />
        )}
        {member.documents && member.documents.length > 0 && (
          <>
            <h4 className="font-semibold">Documents:</h4>
            <ul className="list-disc list-inside">
              {member.documents.map((doc, docIndex) => (
                <li key={docIndex}>
                  <a
                    href={`/api/staffMembers/${member.id}/documents/${doc.id}`}
                    download
                    className="text-blue-600 underline"
                  >
                    {doc.document_name}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </li>
    ));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header title="Staff Training Records" />
      <Navbar />
      <main className="flex-1 p-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-700 mb-6">Staff Training Records</h2>
          <ul className="list-none">
            {staffMembers.length === 0 ? <p>No staff records available.</p> : renderStaffMembers()}
          </ul>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default StaffPage;